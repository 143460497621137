import { Sort } from '@angular/material/sort';
import { TableSettings } from '@design-system/components/table-settings';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { PageQuery } from '../../shared/models/page-query';
import {
  ApprovedDeviceConfiguration,
  BidirectionalOperations,
  DeviceDiagnostics,
  DeviceOperationData,
  HealthCheckResponse,
} from '@paldesk/shared-lib/data-access/provisioning-service-generated';
import { DiagnosticsActions } from './diagnostics.actions';

export interface DiagnosticsState extends EntityState<any> {
  serverStates: HealthCheckResponse;
  isServerStatesLoading: boolean;
  hasServerStatesFailed: boolean;
  diagnosticData: DeviceDiagnostics;
  isDiagnosticDataLoading: boolean;
  errorCode?: string;
  noData?: string;
  approvedConfigurations: ApprovedDeviceConfiguration[];
  isApprovedConfigurationsLoading: boolean;
  applicableSimStates: string[];
  isApplicableSimStatesLoading: boolean;
  serverDeviceFirmwareVersions: string[];
  isServerDeviceFirmwareVersionsLoading: boolean;
  deviceOperations: DeviceOperationData[];
  isDeviceOperationsLoading: boolean;
  userSettings: TableSettings[];
  isUserSettingsLoading: boolean;
  query: PageQuery;
  rowCount: number;
  sort: Sort;

  instructionQuery: PageQuery;
  instructionRowCount: number;
  instructions: BidirectionalOperations[];
  isInstructionLoading: boolean;
  isInstructionsCancelPending: { [key: string]: boolean };

  isApplySpecificFirmwarePending: boolean;
  isApplySpecificSimStatePending: boolean;
  isApplySpecificConfigurationPending: boolean;
  isSuperUser: boolean;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: DiagnosticsState = adapter.getInitialState({
  serverStates: {} as HealthCheckResponse,
  isServerStatesLoading: true,
  hasServerStatesFailed: false,
  diagnosticData: {} as DeviceDiagnostics,
  isDiagnosticDataLoading: false,
  errorCode: undefined,
  noData: undefined,
  approvedConfigurations: [] as ApprovedDeviceConfiguration[],
  isApprovedConfigurationsLoading: true,
  applicableSimStates: [] as string[],
  isApplicableSimStatesLoading: true,
  serverDeviceFirmwareVersions: [] as string[],
  deviceOperations: [] as DeviceOperationData[],
  isDeviceOperationsLoading: true,
  userSettings: [] as TableSettings[],
  isUserSettingsLoading: true,
  rowCount: 0,
  query: { pageSize: 3, pageIndex: 0 },
  sort: { active: 'CreatedAt', direction: 'desc' },

  instructionQuery: { pageSize: 3, pageIndex: 0 },
  instructionRowCount: 0,
  instructions: [] as BidirectionalOperations[],
  isInstructionLoading: true,
  isInstructionsCancelPending: {},

  isServerDeviceFirmwareVersionsLoading: true,
  isApplySpecificFirmwarePending: false,
  isApplySpecificSimStatePending: false,
  isApplySpecificConfigurationPending: false,
  isSuperUser: false,
});

const _diagnosticsReducer = createReducer(
  initialState,
  on(DiagnosticsActions.LoadServerStates, (state) => ({
    ...state,
    isServerStatesLoading: true,
    hasServerStatesFailed: false,
  })),
  on(
    DiagnosticsActions.LoadServerStatesLoadDiagnosticsSuccess,
    (state, { payload }) => ({
      ...state,
      serverStates: payload,
      isServerStatesLoading: false,
      hasServerStatesFailed: false,
    }),
  ),
  on(DiagnosticsActions.LoadServerStatesError, (state) => ({
    ...state,
    isServerStatesLoading: false,
    hasServerStatesFailed: true,
  })),
  on(DiagnosticsActions.LoadDiagnostics, (state) => ({
    ...state,
    isDiagnosticDataLoading: true,
    errorCode: undefined,
    noData: undefined,
    isInstructionLoading: true,
    instructions: initialState.instructions,
  })),
  on(DiagnosticsActions.LoadDiagnosticsSuccess, (state, { payload }) => ({
    ...state,
    diagnosticData: payload,
    isDiagnosticDataLoading: false,
    errorCode: undefined,
    noData: undefined,
  })),
  on(DiagnosticsActions.LoadDiagnosticsError, (state, { payload }) => ({
    ...state,
    isDiagnosticDataLoading: false,
    errorCode: payload,
  })),
  on(DiagnosticsActions.LoadDiagnosticsNoData, (state, { payload }) => ({
    ...state,
    isDiagnosticDataLoading: false,
    noData: payload,
  })),

  on(DiagnosticsActions.LoadSuperUserData, (state) => ({
    ...state,
    approvedConfigurations: initialState.approvedConfigurations,
    isApprovedConfigurationsLoading: true,
    applicableSimStates: initialState.applicableSimStates,
    isApplicableSimStatesLoading: true,
    serverDeviceFirmwareVersions: initialState.serverDeviceFirmwareVersions,
    isServerDeviceFirmwareVersionsLoading: true,
    isDeviceOperationsLoading: true,
    isInstructionLoading: true,
    isSuperUser: true,
  })),
  on(
    DiagnosticsActions.LoadApprovedConfigurationsSuccess,
    (state, { payload }) => ({
      ...state,
      approvedConfigurations: payload,
      isApprovedConfigurationsLoading: false,
    }),
  ),
  on(DiagnosticsActions.LoadApprovedConfigurationsError, (state) => ({
    ...state,
    isApprovedConfigurationsLoading: false,
  })),
  on(
    DiagnosticsActions.LoadApplicableSimStatesSuccess,
    (state, { payload }) => ({
      ...state,
      applicableSimStates: payload,
      isApplicableSimStatesLoading: false,
    }),
  ),
  on(DiagnosticsActions.LoadApplicableSimStatesError, (state) => ({
    ...state,
    isApplicableSimStatesLoading: false,
  })),
  on(
    DiagnosticsActions.LoadServerDeviceFirmwareVersionsSuccess,
    (state, { payload }) => ({
      ...state,
      serverDeviceFirmwareVersions: payload,
      isServerDeviceFirmwareVersionsLoading: false,
    }),
  ),
  on(DiagnosticsActions.LoadServerDeviceFirmwareVersionsError, (state) => ({
    ...state,
    serverDeviceFirmwareVersions: initialState.serverDeviceFirmwareVersions,
    isServerDeviceFirmwareVersionsLoading: false,
  })),
  on(DiagnosticsActions.LoadDeviceOperationsSuccess, (state, { payload }) => ({
    ...state,
    deviceOperations: payload?.messages
      ? payload.messages
      : initialState.deviceOperations,
    rowCount: payload?.row_count ? payload.row_count : state.rowCount,
    isDeviceOperationsLoading: false,
  })),
  on(DiagnosticsActions.LoadDeviceOperationsError, (state) => ({
    ...state,
    isDeviceOperationsLoading: false,
  })),
  on(DiagnosticsActions.SaveQueryDeviceOperations, (state, { payload }) => ({
    ...state,
    query: payload,
  })),
  on(DiagnosticsActions.SaveSortDeviceOperations, (state, { payload }) => ({
    ...state,
    sort: payload,
  })),

  on(DiagnosticsActions.LoadUserTableSettingsDeviceOperations, (state) => ({
    ...state,
    isUserSettingsLoading: true,
    userSettings: initialState.userSettings,
  })),
  on(
    DiagnosticsActions.LoadUserTableSettingsDeviceOperationsSuccess,
    (state, { payload }) => ({
      ...state,
      isUserSettingsLoading: false,
      userSettings: payload,
    }),
  ),

  on(DiagnosticsActions.PostApplySpecificFirmware, (state) => ({
    ...state,
    isApplySpecificFirmwarePending: true,
  })),
  on(DiagnosticsActions.PostApplySpecificFirmwareSuccess, (state) => ({
    ...state,
    isApplySpecificFirmwarePending: false,
  })),
  on(DiagnosticsActions.PostApplySpecificFirmwareError, (state) => ({
    ...state,
    isApplySpecificFirmwarePending: false,
  })),
  on(DiagnosticsActions.PostApplySpecificSimState, (state) => ({
    ...state,
    isApplySpecificSimStatePending: true,
  })),
  on(DiagnosticsActions.PostApplySpecificSimStateSuccess, (state) => ({
    ...state,
    isApplySpecificSimStatePending: false,
  })),
  on(DiagnosticsActions.PostApplySpecificSimStateError, (state) => ({
    ...state,
    isApplySpecificSimStatePending: false,
  })),
  on(DiagnosticsActions.PostApplySpecificConfiguration, (state) => ({
    ...state,
    isApplySpecificConfigurationPending: true,
  })),
  on(DiagnosticsActions.PostApplySpecificConfigurationSuccess, (state) => ({
    ...state,
    isApplySpecificConfigurationPending: false,
  })),
  on(DiagnosticsActions.PostApplySpecificConfigurationError, (state) => ({
    ...state,
    isApplySpecificConfigurationPending: false,
  })),

  on(DiagnosticsActions.LoadDeviceInstructionSuccess, (state, { payload }) => ({
    ...state,
    instructions: payload?.results
      ? payload.results
      : initialState.instructions,
    instructionRowCount: payload?.row_count
      ? payload.row_count
      : state.rowCount,
    isInstructionLoading: false,
  })),
  on(DiagnosticsActions.SaveQueryDeviceInstruction, (state, { payload }) => ({
    ...state,
    instructionQuery: payload,
  })),
  on(DiagnosticsActions.PutAbortDeviceInstruction, (state, { id }) => ({
    ...state,
    isInstructionsCancelPending: {
      ...state.isInstructionsCancelPending,
      [id]: true,
    },
  })),
  on(
    DiagnosticsActions.PutAbortDeviceInstructionSuccess,
    DiagnosticsActions.PutAbortDeviceInstructionError,
    (state, { id }) => ({
      ...state,
      isInstructionsCancelPending: {
        ...state.isInstructionsCancelPending,
        [id]: false,
      },
    }),
  ),
);
export function diagnosticsReducer(state, action: Action): DiagnosticsState {
  return _diagnosticsReducer(state, action);
}
