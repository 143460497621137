import { createSelector } from '@ngrx/store';
import { TelematicDoctorState } from '..';

export namespace DiagnosticsSelectors {
  export const getDiagnosticsState = (state: TelematicDoctorState) =>
    state.diagnostics;
  export const getServerStates = createSelector(
    getDiagnosticsState,
    (state) => state.serverStates,
  );
  export const getIsServerStatesLoading = createSelector(
    getDiagnosticsState,
    (state) => state.isServerStatesLoading,
  );
  export const getHasServerStatesFailed = createSelector(
    getDiagnosticsState,
    (state) => state.hasServerStatesFailed,
  );
  export const getDiagnosticData = createSelector(
    getDiagnosticsState,
    (state) => state?.diagnosticData,
  );
  export const getIsDiagnosticDataLoading = createSelector(
    getDiagnosticsState,
    (state) => state.isDiagnosticDataLoading,
  );
  export const getIsErrorOccured = createSelector(
    getDiagnosticsState,
    (state) => state.errorCode,
  );
  export const getNoDataOccured = createSelector(
    getDiagnosticsState,
    (state) => state.noData,
  );
  export const getApprovedConfigurations = createSelector(
    getDiagnosticsState,
    (state) => state.approvedConfigurations,
  );
  export const getIsApprovedConfigurationsLoading = createSelector(
    getDiagnosticsState,
    (state) => state.isApprovedConfigurationsLoading,
  );
  export const getApplicableSimStates = createSelector(
    getDiagnosticsState,
    (state) => state.applicableSimStates,
  );
  export const getIsApplicableSimStatesLoading = createSelector(
    getDiagnosticsState,
    (state) => state.isApplicableSimStatesLoading,
  );
  export const getServerDeviceFirmwareVersions = createSelector(
    getDiagnosticsState,
    (state) => state.serverDeviceFirmwareVersions,
  );
  export const getIsServerDeviceFirmwareVersionsLoading = createSelector(
    getDiagnosticsState,
    (state) => state.isServerDeviceFirmwareVersionsLoading,
  );
  export const getIsApplySpecificFirmwarePending = createSelector(
    getDiagnosticsState,
    (state) => state.isApplySpecificFirmwarePending,
  );
  export const getIsApplySpecificSimStatePending = createSelector(
    getDiagnosticsState,
    (state) => state.isApplySpecificSimStatePending,
  );
  export const getIsApplySpecificConfigurationPending = createSelector(
    getDiagnosticsState,
    (state) => state.isApplySpecificConfigurationPending,
  );

  export const getEID = createSelector(
    getDiagnosticsState,
    (state) => state?.diagnosticData?.eid,
  );
  export const getDeviceOperations = createSelector(
    getDiagnosticsState,
    (state) => state.deviceOperations,
  );
  export const getIsDeviceOperationsLoading = createSelector(
    getDiagnosticsState,
    (state) => state.isDeviceOperationsLoading,
  );
  export const getPageQuery = createSelector(
    getDiagnosticsState,
    (state) => state.query,
  );
  export const getSort = createSelector(
    getDiagnosticsState,
    (state) => state.sort,
  );
  export const getRowCount = createSelector(
    getDiagnosticsState,
    (state) => state.rowCount,
  );
  export const getUserSettings = createSelector(
    getDiagnosticsState,
    (state) => state.userSettings,
  );
  export const getIsUserSettingsLoading = createSelector(
    getDiagnosticsState,
    (state) => state.isUserSettingsLoading,
  );
  export const getIsSuperUser = createSelector(
    getDiagnosticsState,
    (state) => state.isSuperUser,
  );

  export const getInstructionsQuery = createSelector(
    getDiagnosticsState,
    (state) => state.instructionQuery,
  );
  export const getInstructionRowCount = createSelector(
    getDiagnosticsState,
    (state) => state.instructionRowCount,
  );
  export const getInstructions = createSelector(
    getDiagnosticsState,
    (state) => state.instructions,
  );
  export const getIsInstructionLoading = createSelector(
    getDiagnosticsState,
    (state) => state.isInstructionLoading,
  );
  export const getIsInstructionsCancelPending = createSelector(
    getDiagnosticsState,
    (state) => state.isInstructionsCancelPending,
  );
}
