import { createAction, props } from '@ngrx/store';

export namespace SnackbarActions {
  export const ShowInfo = createAction(
    '[Snackbar] SHOW_INFO',
    props<{ message: string }>(),
  );

  export const ShowSuccess = createAction(
    '[Snackbar] SHOW_SUCCESS',
    props<{ message: string }>(),
  );

  export const ShowWarning = createAction(
    '[Snackbar] SHOW_WARNING',
    props<{ message: string }>(),
  );

  export const ShowError = createAction(
    '[Snackbar] SHOW_ERROR',
    props<{ message: string; error?: any }>(),
  );
}
