/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-identical-functions */
import { Injectable, inject } from '@angular/core';
import { TableSettingsService } from '@design-system/components/table-settings';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  DeviceDiagnostics,
  DoctorService,
} from '@paldesk/shared-lib/data-access/provisioning-service-generated';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { TelematicDoctorState } from '..';
import { InputSelectors } from '../input';
import { SnackbarActions } from '../snackbar/snackbar.actions';
import { DiagnosticsActions } from './diagnostics.actions';
import { DiagnosticsSelectors } from './diagnostics.selectors';

const internalError = 'common.internal_error';
const noData = 'general.no_data_available';
const firmwareSuccessMessage =
  'analytics.device_information.device_main_firmware.firmware_update_success';
const firmwareErrorMessage =
  'analytics.device_information.device_main_firmware.firmware_update_error';

const simStatesSuccessMessage =
  'analytics.connectivity_information.sim_state_update_success';
const simStatesErrorMessage =
  'analytics.connectivity_information.sim_state_update_error';

const configurationSuccessMessage =
  'analytics.device_information.device_configuration.configuration_update_success';
const configurationErrorMessage =
  'analytics.device_information.device_configuration.configuration_update_error';

const abortSuccessMessage = 'analytics.device_operations.abort_message_success';
const abortErrorMessage = 'analytics.device_operations.abort_message_error';
const cancelInstructionsSuccessMessage =
  'analytics.device_instruction.cancel_message_success';
const cancelInstructionsErrorMessage =
  'analytics.device_instruction.cancel_message_error';

@Injectable()
export class DiagnosticsEffects {
  private actions$: Actions = inject(Actions);
  private _store: Store<TelematicDoctorState> = inject(Store);

  loadServerStates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DiagnosticsActions.LoadServerStates),
      switchMap(() =>
        this.doctorService.getServerStates().pipe(
          map((data) =>
            DiagnosticsActions.LoadServerStatesLoadDiagnosticsSuccess({
              payload: data,
            }),
          ),
          catchError(() => [DiagnosticsActions.LoadServerStatesError()]),
        ),
      ),
    ),
  );

  LoadDiagnostics$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DiagnosticsActions.LoadDiagnostics),
      withLatestFrom(this._store.select(InputSelectors.getIdentifier)),
      filter(([, identifier]) =>
        Boolean(identifier.eid?.length && identifier.equipmentNumber?.length),
      ),
      switchMap(([, identifier]) =>
        this.doctorService
          .getDiagnosticsData(
            identifier.eid,
            identifier.equipmentNumber,
            'response',
          )
          .pipe(
            mergeMap(
              (resp) => <Action[]>(resp.status === 204
                  ? [
                      DiagnosticsActions.LoadDiagnosticsNoData({
                        payload: this.translatePipe.instant(noData),
                      }),
                    ]
                  : resp.status === 200
                    ? [
                        DiagnosticsActions.LoadDiagnosticsSuccess({
                          payload: resp.body || ({} as DeviceDiagnostics),
                        }),
                      ]
                    : [
                        DiagnosticsActions.LoadDiagnosticsError({
                          payload: resp.status.toString(),
                        }),
                      ]),
            ),
            catchError(() => [
              DiagnosticsActions.LoadDiagnosticsError({
                payload: this.translatePipe.instant(internalError),
              }),
            ]),
          ),
      ),
    ),
  );

  LoadDiagnosticsEID$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DiagnosticsActions.LoadDiagnostics),
      withLatestFrom(this._store.select(InputSelectors.getIdentifier)),
      filter(([, identifier]) => Boolean(identifier.eid?.length)),
      switchMap(([, identifier]) =>
        this.doctorService
          .getDiagnosticsDataForDevice(identifier.eid, 'response')
          .pipe(
            mergeMap(
              (resp) => <Action[]>(resp.status === 204
                  ? [
                      DiagnosticsActions.LoadDiagnosticsNoData({
                        payload: this.translatePipe.instant(noData),
                      }),
                    ]
                  : resp.status === 200
                    ? [
                        DiagnosticsActions.LoadDiagnosticsSuccess({
                          payload: resp.body || ({} as DeviceDiagnostics),
                        }),
                      ]
                    : [
                        DiagnosticsActions.LoadDiagnosticsError({
                          payload: resp.status.toString(),
                        }),
                      ]),
            ),
            catchError(() => [
              DiagnosticsActions.LoadDiagnosticsError({
                payload: this.translatePipe.instant(internalError),
              }),
            ]),
          ),
      ),
    ),
  );

  LoadDiagnosticsEquipmentNumber$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DiagnosticsActions.LoadDiagnostics),
      withLatestFrom(this._store.select(InputSelectors.getIdentifier)),
      filter(([, identifier]) => Boolean(identifier.equipmentNumber?.length)),
      switchMap(([, identifier]) =>
        this.doctorService
          .getDiagnosticsDataForEquipment(
            identifier.equipmentNumber,
            'response',
          )
          .pipe(
            mergeMap(
              (resp) => <Action[]>(resp.status === 204
                  ? [
                      DiagnosticsActions.LoadDiagnosticsNoData({
                        payload: this.translatePipe.instant(noData),
                      }),
                    ]
                  : resp.status === 200
                    ? [
                        DiagnosticsActions.LoadDiagnosticsSuccess({
                          payload: resp.body || ({} as DeviceDiagnostics),
                        }),
                      ]
                    : [
                        DiagnosticsActions.LoadDiagnosticsError({
                          payload: resp.status.toString(),
                        }),
                      ]),
            ),
            catchError(() => [
              DiagnosticsActions.LoadDiagnosticsError({
                payload: this.translatePipe.instant(internalError),
              }),
            ]),
          ),
      ),
    ),
  );

  LoadApplicableSimStates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DiagnosticsActions.LoadSuperUserData),
      switchMap(() =>
        this.doctorService.getApplicableSimStates().pipe(
          map((data) =>
            DiagnosticsActions.LoadApplicableSimStatesSuccess({
              payload: data,
            }),
          ),
          catchError(() => [DiagnosticsActions.LoadApplicableSimStatesError()]),
        ),
      ),
    ),
  );

  LoadApprovedConfigurations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DiagnosticsActions.LoadSuperUserData),
      switchMap(() =>
        this.doctorService.getApprovedConfigurations().pipe(
          map((data) =>
            DiagnosticsActions.LoadApprovedConfigurationsSuccess({
              payload: data,
            }),
          ),
          catchError(() => [
            DiagnosticsActions.LoadApprovedConfigurationsError(),
          ]),
        ),
      ),
    ),
  );

  LoadServerDeviceFirmwareVersions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DiagnosticsActions.LoadSuperUserData),
      switchMap(() =>
        this.doctorService.getServerDeviceFirmwareVersions().pipe(
          map((data) =>
            DiagnosticsActions.LoadServerDeviceFirmwareVersionsSuccess({
              payload: data,
            }),
          ),
          catchError(() => [
            DiagnosticsActions.LoadServerDeviceFirmwareVersionsError(),
          ]),
        ),
      ),
    ),
  );

  LoadDeviceOperations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DiagnosticsActions.LoadDiagnosticsSuccess,
        DiagnosticsActions.SaveQueryDeviceOperations,
        DiagnosticsActions.SaveSortDeviceOperations,
        DiagnosticsActions.PostApplySpecificSimStateSuccess,
        DiagnosticsActions.PostApplySpecificFirmwareSuccess,
        DiagnosticsActions.PostApplySpecificConfigurationSuccess,
        DiagnosticsActions.PostAbortDeviceOperationSuccess,
      ),
      withLatestFrom(
        this._store.pipe(select(DiagnosticsSelectors.getEID), filterTruthy()),
        this._store.select(DiagnosticsSelectors.getPageQuery),
        this._store.select(DiagnosticsSelectors.getSort),
      ),
      switchMap(([, eid, pageQuery, sort]) =>
        this.doctorService
          .getDeviceOperationsForDevice(
            eid,
            pageQuery.pageIndex,
            pageQuery.pageSize,
            sort.active,
            sort.direction,
          )
          .pipe(
            map((data) =>
              DiagnosticsActions.LoadDeviceOperationsSuccess({
                payload: data,
              }),
            ),
            catchError(() => [DiagnosticsActions.LoadDeviceOperationsError()]),
          ),
      ),
    ),
  );

  LoadDeviceInstruction$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DiagnosticsActions.LoadDiagnosticsSuccess,
        DiagnosticsActions.SaveQueryDeviceInstruction,
        DiagnosticsActions.PutAbortDeviceInstructionSuccess,
      ),
      withLatestFrom(
        this._store.pipe(select(DiagnosticsSelectors.getEID), filterTruthy()),
        this._store.select(DiagnosticsSelectors.getInstructionsQuery),
      ),
      switchMap(([, eid, pageQuery]) =>
        this.doctorService
          .getBidirectionalCommunicationHistory(
            eid,
            pageQuery.pageIndex,
            pageQuery.pageSize,
          )
          .pipe(
            map((data) =>
              DiagnosticsActions.LoadDeviceInstructionSuccess({
                payload: data,
              }),
            ),
            catchError(() => [DiagnosticsActions.LoadDeviceInstructionError()]),
          ),
      ),
    ),
  );

  LoadUserTableSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiagnosticsActions.LoadUserTableSettingsDeviceOperations),
      switchMap((payload) =>
        this.tableSettingsService
          .get(payload.settingsKey, payload.allColumns)
          .pipe(
            map((data) =>
              DiagnosticsActions.LoadUserTableSettingsDeviceOperationsSuccess({
                payload: data,
              }),
            ),
          ),
      ),
    ),
  );

  PostApplySpecificFirmware$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DiagnosticsActions.PostApplySpecificFirmware),
      withLatestFrom(
        this._store.pipe(select(DiagnosticsSelectors.getEID), filterTruthy()),
      ),
      switchMap(([payload, eid]) =>
        this.doctorService.applySpecificFirmware(eid, payload.payload).pipe(
          mergeMap(() => [
            DiagnosticsActions.PostApplySpecificFirmwareSuccess(),
            SnackbarActions.ShowSuccess({
              message: this.translatePipe.instant(firmwareSuccessMessage),
            }),
          ]),
          catchError(() => [
            DiagnosticsActions.PostApplySpecificFirmwareError(),
            SnackbarActions.ShowError({
              message: this.translatePipe.instant(firmwareErrorMessage),
            }),
          ]),
        ),
      ),
    ),
  );

  PostApplySpecificSimState$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DiagnosticsActions.PostApplySpecificSimState),
      withLatestFrom(
        this._store.pipe(select(DiagnosticsSelectors.getEID), filterTruthy()),
      ),
      switchMap(([payload, eid]) =>
        this.doctorService.applySpecificSimState(eid, payload.payload).pipe(
          mergeMap(() => [
            DiagnosticsActions.PostApplySpecificSimStateSuccess(),
            SnackbarActions.ShowSuccess({
              message: this.translatePipe.instant(simStatesSuccessMessage),
            }),
          ]),
          catchError(() => [
            DiagnosticsActions.PostApplySpecificSimStateError(),
            SnackbarActions.ShowError({
              message: this.translatePipe.instant(simStatesErrorMessage),
            }),
          ]),
        ),
      ),
    ),
  );

  PostApplySpecificConfiguration$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DiagnosticsActions.PostApplySpecificConfiguration),
      withLatestFrom(
        this._store.pipe(select(DiagnosticsSelectors.getEID), filterTruthy()),
      ),
      switchMap(([payload, eid]) =>
        this.doctorService
          .applySpecificConfiguration(eid, payload.payload)
          .pipe(
            mergeMap(() => [
              DiagnosticsActions.PostApplySpecificConfigurationSuccess(),
              SnackbarActions.ShowSuccess({
                message: this.translatePipe.instant(
                  configurationSuccessMessage,
                ),
              }),
            ]),
            catchError(() => [
              DiagnosticsActions.PostApplySpecificConfigurationError(),
              SnackbarActions.ShowError({
                message: this.translatePipe.instant(configurationErrorMessage),
              }),
            ]),
          ),
      ),
    ),
  );

  PostAbortDeviceOperation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DiagnosticsActions.PostAbortDeviceOperation),
      switchMap((payload) =>
        this.doctorService
          .abortDeviceOperation(payload.id, payload.payload)
          .pipe(
            mergeMap(() => [
              DiagnosticsActions.PostAbortDeviceOperationSuccess(),
              SnackbarActions.ShowSuccess({
                message: this.translatePipe.instant(abortSuccessMessage),
              }),
            ]),
            catchError(() => [
              DiagnosticsActions.PostAbortDeviceOperationError(),
              SnackbarActions.ShowError({
                message: this.translatePipe.instant(abortErrorMessage),
              }),
            ]),
          ),
      ),
    ),
  );

  PutAbortDeviceInstruction$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DiagnosticsActions.PutAbortDeviceInstruction),
      withLatestFrom(this._store.select(InputSelectors.getIdentifier)),
      switchMap(([{ id }, { eid }]) =>
        this.doctorService.v1InstructionsEidCancelIdPut(eid, id).pipe(
          mergeMap(() => [
            DiagnosticsActions.PutAbortDeviceInstructionSuccess({ id }),
            SnackbarActions.ShowSuccess({
              message: this.translatePipe.instant(
                cancelInstructionsSuccessMessage,
              ),
            }),
          ]),
          catchError(() => [
            DiagnosticsActions.PutAbortDeviceInstructionError({ id }),
            SnackbarActions.ShowError({
              message: this.translatePipe.instant(
                cancelInstructionsErrorMessage,
              ),
            }),
          ]),
        ),
      ),
    ),
  );

  constructor(
    private doctorService: DoctorService,
    private tableSettingsService: TableSettingsService,
    private translatePipe: TranslateService,
  ) {}
}
